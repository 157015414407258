import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Importance of competition in halifax internet market 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Importance of Competition in Halifax's Internet Market</h1>
        
          <StaticImage
          layout="constrained"
          src="images/Competition-purple-cow-internet.png"
          alt="Wifi logos for Purple Cow Internet cheap internet"
          /> 
       
        <p>As an owner of Purple Cow Internet, I believe it is crucial to emphasize the importance of having businesses like ours offering services in Nova Scotia at lower rates compared to our competitors. Currently, we find ourselves in an oligopoly situation, where only two main competitors dominate the market and keep their prices inflated. This lack of competition allows them to maintain high prices, taking advantage of the limited options available to consumers. However, Purple Cow Internet aims to challenge this status quo and bring about positive change.</p>

        <p>In a time when inflation is skyrocketing and the carbon tax is driving up fuel costs, Purple Cow Internet stands firm in our commitment to keeping our prices affordable and accessible. While other service providers may succumb to the pressures of rising expenses, we understand the importance of providing a reliable internet connection at a reasonable price. Our monthly rate of only $60 is a testament to our dedication to our customers' needs and financial well-being.</p>

        <p>The growth of Purple Cow Internet is not just about offering cheap internet; it is about sending a powerful message to the oligopoly. With each new subscriber who joins our service, we demonstrate that there is a demand for fair pricing and improved customer service. By choosing Purple Cow Internet, customers show their dissatisfaction with the inflated prices set by the dominant players in the market. We believe that customer choice is a catalyst for change, and as more people join our network, the oligopoly will be compelled to take notice and reevaluate their pricing strategies.</p>

        <p>Our fight is not limited to reducing costs alone. Purple Cow Internet is here to support our customers. We have your back, and we are dedicated to providing the best possible internet experience. We understand that affordable pricing is just one aspect of a great service. We strive to offer exceptional customer support, reliable connectivity, and innovative solutions to ensure our customers have the best internet experience possible.</p>

        <p>Our ultimate goal is to drive down the cost of internet services, not only in Nova Scotia but also in Prince Edward Island and Newfoundland. We believe that every individual and business should have access to affordable and reliable internet, regardless of their location or financial situation. Purple Cow Internet is at the forefront of this movement, working tirelessly to make cheap, high-quality internet a reality for everyone in the region.</p>

        <p>In conclusion, Purple Cow Internet is more than just a service provider. We are a force for change in the industry, challenging the inflated prices and lackluster customer service offered by the oligopoly. We are committed to providing affordable internet at a time when prices continue to rise. With your support, we can continue to drive down costs and demand better service, ensuring that everyone in Nova Scotia, Prince Edward Island, and Newfoundland has access to the cheap, best-in-class internet they deserve.</p>

         <p>
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
